

<template>
  <label-cmpt
    :showTitle="'职工标签-单位阅读'"
    :showRefreshDataCmp="false"
    :taskType="'uLabel'"
    :midType="'dept'"
    :labelType="'user'"
    :selfColumn="selfColumn"
  ></label-cmpt>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";

import labelCmpt from "./labelCmptNew.vue";
// import labelCmpt from "./labelCmpt.vue";

export default {
  name: "userLabelDeptStats",
  components: {
    labelCmpt,
  },
  data() {
    return {
      selfColumn: [
        {
          name: "阅读数量",
          value: "count",
        },
        // {
        //   name: "路局阅读",
        //   value: "count_lj",
        // },
        // {
        //   name: "站段阅读",
        //   value: "count_zd",
        // },
        {
          name: "点赞数量",
          value: "like",
        },

        // {
        //   name: "路局点赞",
        //   value: "like_lj",
        // },
        // {
        //   name: "站段点赞",
        //   value: "like_zd",
        // },

        {
          name: "分享数量",
          value: "share",
        },
        // {
        //   name: "路局分享",
        //   value: "share_lj",
        // },

        // {
        //   name: "站段分享",
        //   value: "share_zd",
        // },

        {
          name: "评论数量",
          value: "comment",
        },

        // {
        //   name: "路局评论",
        //   value: "comment_lj",
        // },
        // {
        //   name: "站段评论",
        //   value: "comment_zd",
        // },
        {
          name: "阅读率",
          value: "countRate",
        },
        // {
        //   name: "优质评论",
        //   value: "commentView",
        // },
      ],
    };
  },
  methods: {},

  destroyed() {},
  async created() {},
};
</script>

<style scoped>
</style>
